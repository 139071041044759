import { MapperContext } from '@empathyco/x-adapter';
import { PlatformOyshoResult } from '../models/index';
import { getImageUrl, ImageKind, ImageSize } from './inditex-url.utils';
import { mapPrice } from './price.utils';
import { mapSizes } from './size.utils';

export function mapVariants(rawResult: PlatformOyshoResult, context: MapperContext = {}): any[] {
  const store = (context.requestParameters?.extraParams as Record<string, string>).store;

  return rawResult.__variants
    ? rawResult.__variants
        ?.map(variant => {
          const variantImage = { ...rawResult.image, url: variant.imagePreviewUrl };
          const variantImageUrl = getImageUrl(variantImage, store);

          return {
            images: [variantImageUrl],
            sizesByStock: rawResult.sizesByStock,
            color: {
              id: variant.color.id,
              name: variant.color.name ? variant.color.name[0] : '',
              image: variant.color.image
                ? getImageUrl(variant.color.image, store, {
                    kind: ImageKind.color,
                    size: ImageSize.small
                  })
                : ''
            },
            sizes: mapSizes(variant),
            prices: mapPrice(rawResult, context),
            imagePreviewUrl: variantImageUrl
          };
        })
        .filter(variant => variant.sizes.length > 0)
    : [];
}
