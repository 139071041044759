import { PlatformOyshoResult } from '../models/index';
import { MapperContext } from '@empathyco/x-adapter';
import { getImageUrl, ImageKind, ImageSize } from './inditex-url.utils';

export function generateColorUrl(baseURL: string, colorId: string): string {
  const url = new URL(baseURL);
  url.searchParams.set('colorId', colorId);
  return url.href;
}

export function mapColorImage(rawResult: PlatformOyshoResult, context: MapperContext = {}): string {
  const store = (context.requestParameters?.extraParams as Record<string, string>).store;

  return rawResult.color.image
    ? getImageUrl(rawResult.color.image, store, {
        kind: ImageKind.color,
        size: ImageSize.small
      })
    : '';
}
