import { MapperContext } from '@empathyco/x-adapter';
import { ResultPrice } from '@empathyco/x-types';
import { PlatformOyshoResult } from '../models/oysho.model';

export function mapPrice(rawResult: PlatformOyshoResult, context: MapperContext = {}): ResultPrice {
  // TODO currently the sku endpoint returns the __prices field as an array
  if (Array.isArray(rawResult.__prices)) {
    rawResult.__prices = rawResult.__prices[0];
  }
  const currentPrice = rawResult.__prices?.current;
  const futurePrice = rawResult.__prices?.future;

  // ITX prewarmingFunction expects the promotionId to be a number
  const currentPromotionId = Number(currentPrice?.promotionId) || undefined;
  const futurePromotionId = Number(futurePrice?.promotionId) || undefined;

  const originalRange = rawResult.__prices?.original?.priceRange;
  const previousRange = rawResult.__prices?.previous?.priceRange;
  const currentRange = rawResult.__prices?.current?.priceRange;
  const futureRange = rawResult.__prices?.future?.priceRange;

  const originalValue = Number.parseFloat(originalRange?.maxPrice) || undefined;
  const previousValue = Number.parseFloat(previousRange?.maxPrice) || undefined;
  const futureValue = Number.parseFloat(futureRange?.maxPrice) || undefined;
  const value = Number.parseFloat(currentRange?.maxPrice) || undefined;

  const hasDiscount = value! < previousValue!;

  context.price = {
    originalValue,
    previousValue,
    value,
    futureValue,
    currentPromotionId,
    futurePromotionId,
    hasDiscount
  };

  return {
    originalValue,
    previousValue,
    value,
    futureValue,
    hasDiscount,
    currentPromotionId,
    futurePromotionId
  } as ResultPrice;
}
