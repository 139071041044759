import { platformAdapter, resultSchema } from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
import { OyshoResultColor, PlatformOyshoSize } from './models/oysho.model';
import { mapVariants, mapColorImage, mapPrice, mapResultUrl } from './utils';
import { PreWarming } from './models';

type productReference = {
  campaign: string;
  color: string;
  model: string;
  quality: string;
  type: number;
};

export const adapter = platformAdapter;
// TODO check problems ts out of memory with types when using PlatformOyshoResult
resultSchema.$override<any, Partial<Result>>({
  images: ({ imagePreviewUrl }) => [imagePreviewUrl],
  id: 'internal_id',
  url: mapResultUrl,
  color: {
    id: 'color.id',
    name: 'color.name',
    image: mapColorImage
  },
  // reference: '__boostingReference',
  sizes: 'sizes',
  selectedColor: ({ color }) => color.id,
  price: mapPrice,
  variants: mapVariants,
  moca: ({ reference }: { reference: { model: string; quality: string; color: string } }) =>
    `${reference.model}${reference.quality}`,
  productId: 'productId',
  reference: ({ reference }: { reference: productReference }) =>
    `${reference.model}${reference.quality}${reference.color}-${reference.campaign}`
});

declare module '@empathyco/x-types' {
  export interface Result {
    sizes: PlatformOyshoSize[];
    color: OyshoResultColor;
    selectedColor: string;
    preWarming?: PreWarming;
    reference: string;
    moca: string;
    productId: string;
  }
}
