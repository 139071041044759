import { InstallXOptions, createWireFromFunction, XEventPayload } from '@empathyco/x-components';
import { I18n } from '@empathyco/x-archetype-utils';
import { SearchRequest } from '@empathyco/x-types';
import App from '../App.vue';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/oysho-api.adapter';

const updateResultVariantPriceWire = createWireFromFunction<
  XEventPayload<'UserSelectedAResultVariant'>
>(async ({ store, eventPayload }) => {
  const resultFromEvent = eventPayload.result;
  const variantSelected = eventPayload.variant;
  const moca = resultFromEvent.moca;
  const colorSelectedId = variantSelected.color.id;
  const searchRequest = { ...store.getters['x/search/request'] } as SearchRequest;
  delete searchRequest.extraParams?.query;
  searchRequest.query = `${moca}${colorSelectedId}`;
  const { results } = await adapter.identifierResults(searchRequest);
  const result = results.find(result => result.productId === resultFromEvent.productId);
  if (result) {
    result.id = resultFromEvent.id;
    store.commit('x/search/updateResult', { id: result.id, price: result.price, url: result.url });
  }
});

export const installXOptions: InstallXOptions = {
  adapter,
  store,
  app: App,
  xModules: {
    facets: {
      config: {
        filtersStrategyForRequest: 'leaves-only'
      }
    },
    search: {
      wiring: {
        UserSelectedAResultVariant: {
          updateResultVariantPriceWire
        }
      }
    }
  },
  async installExtraPlugins({ vue, snippet }) {
    const i18n = await I18n.create({
      locale: snippet.uiLang,
      device: (snippet.device as string) ?? 'mobile',
      fallbackLocale: 'en',
      messages
    });
    vue.use(i18n);
    vue.prototype.$setLocale = i18n.setLocale.bind(i18n);
    vue.prototype.$setLocaleDevice = i18n.setDevice.bind(i18n);

    return {
      i18n: i18n.vueI18n
    };
  }
};
