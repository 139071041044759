











  import { SnippetCallbacks, SnippetConfig, XOn, XProvide } from '@empathyco/x-components';
  import { DeviceDetector } from '@empathyco/x-components/device';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Dictionary } from '@empathyco/x-utils';
  import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
  import currencies from './i18n/currencies';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import './design-system/design-system.scss';

  @Component({
    components: {
      DeviceDetector,
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class Layer extends Vue {
    protected breakpoints: Dictionary<number> = {
      mobile: 767,
      tablet: 1000,
      desktop: Number.POSITIVE_INFINITY
    };
    protected isOpen = false;

    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    mounted(): void {
      // Stop events propagation
      const eventsToStop = ['click', 'mousewheel', 'wheel', 'scroll', 'keyup', 'keydown'];
      eventsToStop.forEach(event =>
        this.$el.addEventListener(event, e => {
          e.stopPropagation();
        })
      );
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @XProvide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @Watch('snippetConfig.lang')
    syncLang(lang: string): void {
      this.$setLocale(lang);
    }

    public excludedExtraParams = [
      'callbacks',
      'productId',
      'uiLang',
      'consent',
      'documentDirection',
      'currency',
      'filters',
      'isSpa',
      'queriesPreview',
      'prewarmingFunction'
    ];
  }
