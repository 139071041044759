import { XInstaller } from '@empathyco/x-components';
import Vue from 'vue';
import { FilterEntityFactory, SingleSelectModifier } from '@empathyco/x-components/facets';
import { installXOptions } from './x-components/plugin.options';

Vue.config.productionTip = false;

// eslint-disable-next-line max-len
FilterEntityFactory.instance.registerModifierByFacetId('hierarchical_category', SingleSelectModifier);
FilterEntityFactory.instance.registerModifierByFacetId('categoryPaths', SingleSelectModifier);
FilterEntityFactory.instance.registerModifierByFacetId('eb_colors_facet', SingleSelectModifier);

new XInstaller(installXOptions).init();
