import { OyshoVariant, PlatformOyshoSize } from '../models';

/**
 * Maps sizes of a variant to check if they are available.
 *
 * @param variant - The result's variant.
 * @returns The sizes variant with the isOutOfStock information per size.
 */
export function mapSizes(variant: OyshoVariant): PlatformOyshoSize[] {
  return variant.sizes.map(size => {
    const isOutOfStock = !variant.sizesByStock?.includes(size.id);
    return { ...size, isOutOfStock };
  });
}
