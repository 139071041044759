/*
  Export the messages in this file to be auto-loaded.

  A normal re-export like `export { default as en } from './en.messages'`,
  will make the `en` to be loaded immediately and included in the main chunk.

  If you instead prefer the messages to be loaded only when they are needed, export instead
  a function that imports the messages. This way, messages for a specific locale would be loaded
  when the active locale matches them.
 */

// Example of how to make the english language be loaded immediately
// export { default as en } from './en.messages.json';
// export { default as es } from './es.messages.json';

// Example of how to make the spanish messages be lazy loaded, only when the locale is set to `es`
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const ar = () => import('./ar.messages.json');
export const bg = () => import('./bg.messages.json');
export const ca = () => import('./ca_ES.messages.json');
export const cs = () => import('./cs.messages.json');
export const da = () => import('./da.messages.json');
export const de = () => import('./de.messages.json');
export const el = () => import('./el.messages.json');
export const en = () => import('./en.messages.json');
export const es = () => import('./es.messages.json');
export const es_MX = () => import('./es_MX.messages.json');
export const et = () => import('./et.messages.json');
export const eu = () => import('./eu_ES.messages.json');
export const fr = () => import('./fr.messages.json');
export const gl = () => import('./gl_ES.messages.json');
export const hr = () => import('./hr.messages.json');
export const it = () => import('./it.messages.json');
export const ko = () => import('./ko.messages.json');
export const lt = () => import('./lt.messages.json');
export const lv = () => import('./lv.messages.json');
export const mk = () => import('./mk.messages.json');
export const nl = () => import('./nl.messages.json');
export const pl = () => import('./pl.messages.json');
export const pt = () => import('./pt.messages.json');
export const ro = () => import('./ro.messages.json');
export const ru = () => import('./ru.messages.json');
export const sk = () => import('./sk.messages.json');
export const sr = () => import('./sr.messages.json');
export const sv = () => import('./sv.messages.json');
export const th = () => import('./th.messages.json');
export const tr = () => import('./tr.messages.json');
export const uk = () => import('./uk.messages.json');
export const zh = () => import('./zh.messages.json');
/* eslint-enable @typescript-eslint/explicit-function-return-type */
