import { Dictionary } from '@empathyco/x-utils';
import { MapperContext } from '@empathyco/x-adapter';
import { PlatformOyshoImage, PlatformOyshoResult } from '../models/oysho.model';
import { generateColorUrl } from './color.utils';

export interface ImageUrlOptions {
  kind?: ImageKind;
  size?: ImageSize;
}

export const enum ImageKind {
  modelZoom = 1,
  modelFront = 2,
  color = 3,
  productFront = 4,
  productBack = 5,
  modelBack = 6
}

export const enum ImageSize {
  fullSize = 1,
  extraLarge = 2,
  large = 3,
  medium = 4,
  small = 5
}

const CHINA_STORE = '65009628';

export function generateUrl(result: PlatformOyshoResult, additionalParams?: Dictionary): string {
  return window.inditex.encodeUrl({
    action: 'ItxProductPage',
    params: {
      storeId: window.inditex.iStoreId,
      langId: window.inditex.iLangId,
      catalogId: window.inditex.iCatalogId,
      categoryId: result.firstRootCategory ?? 0,
      colorId: window.inditex.colorId,
      productId: result.productId,
      productUrl: result.url,
      productUrlParam: '',
      ...additionalParams
    }
  });
}

export function getSelectedCategoryFilterId(context: MapperContext): string | undefined {
  const selectedFilter = context.requestParameters?.filter as string[];
  const selectedCategory = selectedFilter?.filter(filter => filter.includes('categoryIds'))[0];
  if (selectedCategory) {
    const [, categoryId] = selectedCategory.split(':');
    return categoryId;
  }
}

export const mapResultUrl = (result: PlatformOyshoResult, context: MapperContext = {}): string => {
  const selectedCategoryId = getSelectedCategoryFilterId(context ?? {});
  const url = generateUrl(result, {
    ...(!!selectedCategoryId && { categoryId: selectedCategoryId })
  });
  context.resultUrl = url;
  return generateColorUrl(url, result.color.id);
};

export function getImageUrl(
  image: PlatformOyshoImage,
  store: string,
  { kind = ImageKind.modelZoom, size = ImageSize.large }: ImageUrlOptions = {}
): string {
  return image
    ? `${getImageUrlDomain(store)}/6/photos2${image.url}_${kind}_1_${size}.jpg?t=${image.timestamp}`
    : '';
}

function getImageUrlDomain(store: string): string {
  return store === CHINA_STORE ? `https://static.oysho.cn` : `https://static.oysho.net`;
}
